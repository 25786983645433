import { ref, watch, computed } from '@vue/composition-api';
import axios from '@axios';
import useAuth from '@/auth/useAuth';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { i18n } = useI18nUtils();
function i18nT(str){
    return i18n(str)
  };
export default function useEmployeeGroupList() {
    const refEmployeeListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        { key: 'checkbox', label: '', tdClass: "pr-0" },
        { key: 'name', label: i18nT(`Name`), sortable: true, thClass: "pl-0", tdClass: "pl-0" },
        { key: 'desription', label: i18nT(`Description`), sortable: true },
        { key: 'created', label: i18nT(`Created`), sortable: true },
        { key: 'count', label: i18nT(`Members`), sortable: true },
        {
            key: 'action',
            label: i18nT(`Actions`),
            thClass: 'tbl-actions',
            tdClass: 'sticky-column',
            stickyColumn: true
        },
    ];
    const perPage = ref(10);
    const totalEmployeeGroups = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('id');
    const isSortDirDesc = ref(true);
    const employeeGroups = ref([]);

    const dataMeta = computed(() => {
        const localItemsCount = refEmployeeListTable.value ?
            refEmployeeListTable.value.localItems.length :
            0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalEmployeeGroups.value,
        };
    });

    const refetchData = () => {
        refEmployeeListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, isSortDirDesc, sortBy], () => {
        refetchData();
    });

    const fetchEmployeegroups = (ctx, callback) => {
        const params = {
            page: currentPage.value,
            show_per_page: perPage.value,
            sort_by: sortBy.value,
            sort_order: isSortDirDesc.value ? 'DESC' : 'ASC',
            search: searchQuery.value
        };

        axios
            .get(`employees/employeeGroups`, {
                params,
            })
            .then(({ data }) => {
                employeeGroups.value = data.data.employee_groups;
                callback(data.data.employee_groups);
                totalEmployeeGroups.value = data.data.pagination.records_count;
            });
    };

    return {
        fetchEmployeegroups,
        tableColumns,
        perPage,
        currentPage,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refEmployeeListTable,
        totalEmployeeGroups,
        employeeGroups,
        refetchData,
    };
}
